import React, { useEffect } from 'react'
import { LdsTile, LdsTable, LdsLoadingSpinner, LdsIcon, LdsButton, LdsPagination, LdsTextField, LdsTooltip, LdsModal, useLdsModal, LdsCheckbox, LdsRadio, LdsRadioGroup, LdsOptionSelectorItem, LdsOptionSelector } from '@elilillyco/ux-lds-react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderNav from '../../HeaderNav';
import "../../../assets/components/TACCT/BL/blueList.scss"
import { fetchTacctData, getEntriesToExport } from "../../../store/Components/TACCT/BL/tacctmetadata";
import { Link } from "react-router-dom";
import ExportDataCSV from '../../../data/utils/ExportDataCSV';
import AddNewCatModal from "./AddNewCatModal";
import HistoryModal from '../../shared/HistoryModal';
import { useNavigate } from "react-router-dom";
import pako from "pako";
import { blArchiveValues } from '../../../api/actions/TxmyMgmt/tacctBlOverview/blArchive';
import { blArchive } from '../../../store/Components/TACCT/BL/tacctArchive';

export default function BlueList() {
  const [isCode, setIsCode] = useState('THPC,DSST,CCML')
  const [isCodeType, setIsCodeType] = useState('medical')
  const [isCCMLChecked, setIsCCMLChecked] = useState(true);
  const [isTHPCChecked, setIsTHPCChecked] = useState(true);
  const [isDSSTChecked, setIsDSSTChecked] = useState(true);

  const navigate = useNavigate();
  const data = useSelector(({ overviewSlice }) => overviewSlice.contentTBody);
  const tPages = useSelector(({ overviewSlice }) => overviewSlice.totalPages);
  const loading = useSelector(({ overviewSlice }) => overviewSlice.loading)
  const [csvLoader, setCsvLoader] = React.useState(false);
  const [pageRangeVisible] = useState(5);
  var [statusSearchFld, setIsON] = React.useState(false);
  var [countrySearchFld, setIsCountry] = React.useState(false);
  var [productSearchFld, setIsProduct] = React.useState(false);
  var [categorySearchFld, setIsCategory] = React.useState(false);
  var [subCategorySearchFld, setIsSubCategory] = React.useState(false);
  var [categoryOpDefinitionFld, setIsCatOpDef] = React.useState(false);
  var [subCategoryOpDefinitionSearchFld, setIsSubOpDef] = React.useState(false);
  const [lastEvent, setLastEvent] = useState('');
  const totalPages = tPages;
  const [currentPage, setCurrentPage] = useState(1);
  const records = data;
  const [statusSearch, setStatusSearch] = useState([]);
  var [countrySearch, setcountrySearch] = React.useState('');
  var [productSearch, setproductSearch] = React.useState('');
  var [categorySearch, setcategorySearch] = React.useState('');
  var [subCatSearch, setsubCatSearch] = React.useState('');
  var [catOpSearch, setcatOpSearch] = React.useState('');
  var [subCatOpSearch, setsubCatOpSearch] = React.useState('');
  const { isModalOpen, setIsModalOpen } = useLdsModal();
  const { isModalOpen: isAddNewCatModalOpen, setIsModalOpen: setIsAddNewCatModalOpen } = useLdsModal();
  const [tacticId, setTacticId] = useState(null);
  const { isModalOpen: isAuditModalOpen, setIsModalOpen: setAuditIsModalOpen } = useLdsModal();
  const [isData, setIsData] = useState([...data]);
  const [isChecked, setIsChecked] = useState(false);
  const [getId, setId] = useState();


  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const handleCheckboxChange = (event, id) => {
    const checked = event.target.checked;
    if (checked) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
    }
  };

  const Retpayload = {
    uid: selectedIds,
    active_flag: false
  }

  const archiveIt = () => {
    dispatch(blArchive(Retpayload))
  }

  // Function to handle "Select All" checkbox
  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    if (checked) {
      const allIds = tableData.map(item => item.blue_list_subcategory_detail_id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };


  const clickLink = (e, value) => {
    setLastEvent(`clickLink, value: ${value}`);
    dispatch(fetchTacctData({
      status: statusSearch,
      active_flag: true,
      country: countrySearch,
      cat_def: catOpSearch,
      sub_cat_def: subCatOpSearch,
      sub_cat: subCatSearch,
      cat: categorySearch,
      product: productSearch,
      pageSize: 10,
      pageNumber: value,
      group_purpose_code: isCode,
    }));
  };

  const clickNext = (e, value) => {
    setLastEvent(`clickNext, value: ${value}`);
    dispatch(fetchTacctData({
      status: statusSearch,
      active_flag: true,
      country: countrySearch,
      cat_def: catOpSearch,
      sub_cat_def: subCatOpSearch,
      sub_cat: subCatSearch,
      cat: categorySearch,
      product: productSearch,
      pageSize: 10,
      pageNumber: currentPage + 1,
      group_purpose_code: isCode,
    }));
  };

  // Called when you click the "previous" link/button
  const clickPrev = (e, value) => {
    setLastEvent(`clickPrev, value: ${value}`);
    dispatch(fetchTacctData({
      status: statusSearch,
      active_flag: true,
      country: countrySearch,
      cat_def: catOpSearch,
      sub_cat_def: subCatOpSearch,
      sub_cat: subCatSearch,
      cat: categorySearch,
      product: productSearch,
      pageSize: 10,
      pageNumber: currentPage - 1,
      group_purpose_code: isCode,
    }));
  };

  function RfaButton() {
    window.location.href = '/tacct/request-approval';
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTacctData({
      status: statusSearch || '',
      country: countrySearch,
      active_flag: true,
      cat_def: catOpSearch,
      sub_cat_def: subCatOpSearch,
      sub_cat: subCatSearch,
      cat: categorySearch,
      product: productSearch,
      pageSize: 10,
      pageNumber: 1,
      group_purpose_code: isCode,
    }));
  }, [dispatch, isCode]);

  function checkAddMetaDataRole() {
    const role = sessionStorage.getItem('role');
    if (role === 'omt_tacct_bl_metadata_owner' || role === 'omt_tacct_admin' || role === 'omt_admin') {
      return true;
    }
    return false;
  }

  function checkAddSubcategoryRole() {
    const role = sessionStorage.getItem('role');
    if (role === 'omt_tacct_bl_metadata_requester' || role === 'omt_tacct_admin' || role === 'omt_admin') {
      return true;
    }
    return false;
  }

  function checkApprovalRole() {
    const role = sessionStorage.getItem('role');
    if (role === 'omt_tacct_admin' || role === 'omt_tacct_bl_approver_omni' || role === 'omt_tacct_bl_approver_taxccb' || role === 'omt_tacct_bl_metadata_requester' || role === 'omt_admin') {
      return true;
    }
    return false;
  }

  function checkEditCategoryRole() {
    const role = sessionStorage.getItem('role');
    if (role === 'omt_tacct_admin' || role === 'omt_tacct_bl_metadata_owner' || role === 'omt_admin') {
      return true;
    }
  }

  function searchFld() {
    dispatch(fetchTacctData({
      status: statusSearch,
      country: countrySearch,
      cat_def: catOpSearch,
      sub_cat_def: subCatOpSearch,
      sub_cat: subCatSearch,
      cat: categorySearch,
      product: productSearch,
      pageSize: 10,
      pageNumber: 1,
      group_purpose_code: isCode,
    }));
  }

  function clearSearch() {
    setStatusSearch('')
    setproductSearch('')
    setcountrySearch('')
    setcategorySearch('')
    setsubCatSearch('')
    setcatOpSearch('')
    setsubCatOpSearch('')
    setIsON(false);
    setIsCountry(false)
    setIsProduct(false)
    setIsCategory(false)
    setIsSubCategory(false)
    setIsCatOpDef(false)
    setIsSubOpDef(false)
    dispatch(fetchTacctData({
      status: '',
      country: '',
      cat_def: '',
      sub_cat_def: '',
      sub_cat: '',
      cat: '',
      product: '',
      pageSize: 10,
      pageNumber: 1,
      group_purpose_code: isCode,
    }));
  }


  const checkStatus = (e) => {
    if (e.target.checked) {
      if (!statusSearch.includes(e.target.value)) {
        setStatusSearch([...statusSearch, e.target.value])
      }
    }
    if (!e.target.checked) {
      setStatusSearch(prevStatusSearch => statusSearch.filter(obj => obj !== e.target.value))
    }

  }

  const addNewCat = () => {
    // Prepare the payload
    this.category = this.category.trim();
    this.CategoryOpDef = this.CategoryOpDef.trim();
  }

  function AddSubButton() {
    window.location.href = '/tacct/add-subcategory-attribute'
  }

  const editExistingData = (blue_list_subcategory_detail_id) => {
    navigate({
      pathname: '/tacct/add-subcategory-attribute',
      search: `?id=${blue_list_subcategory_detail_id}`,
    });
  };
  const downloadExcel = async () => {
    setCsvLoader(true);
    await dispatch(getEntriesToExport({
      status: statusSearch,
      country: countrySearch,
      cat_def: catOpSearch,
      sub_cat_def: subCatOpSearch,
      sub_cat: subCatSearch,
      cat: categorySearch,
      product: productSearch,
      group_purpose_code: isCode,
    })).then((data) => {
      const uncomressedDATA = data.payload.data;
      const decodedDATA = atob(uncomressedDATA);
      const charData = decodedDATA.split('').map(x => x.charCodeAt(0));
      const binData = new Uint8Array(charData);
      const dataStr = pako.inflate(binData, { to: 'string' });
      const data2 = JSON.parse(dataStr);

      setCsvLoader(false);
      // console.log('download csv:', data.payload.data);
      // if (data.payload.data.includes('https')) {
      //   window.open(data.payload.data, '_self')
      // }
      ExportDataCSV(data2, "TACCT_BlueList")
    })

  }
  const [isSwitch, setIsSwitch] = useState('med')

  const updateIsCode = (isChecked, value) => {
    let newIsCode = new Set(isCode.split(','));
    if (isChecked) {
      newIsCode.add(value);
    } else {
      newIsCode.delete(value);
    }
    setIsCode(Array.from(newIsCode).join(','));
  };

  function goToArchive() {
    window.location.href = '/tacct/bl-archive'
  }



  return (
    <>
      <HeaderNav msg={"Blue List Content Category & Subcategory"} />
      <LdsTile className='hero' onClick={() => setIsON(false)}>
        <div className="overview-container">
          <div className="overview-title">
            <span className='overview-text'>Overview</span>
            <div className='options-medcom'>
              <LdsRadio
                id="med"
                label="Medical"
                name="but"
                value="medical"
                defaultChecked
                onChange={(e) => {
                  setIsCodeType(e.target.value);
                  setIsCode("CCML,THPC,DSST");
                  setIsCCMLChecked(true);
                  setIsTHPCChecked(true);
                  setIsDSSTChecked(true);
                }}
                error
              />


              {isCodeType === 'medical' ? <div className='checkField0'>
                <LdsCheckbox
                  id='CCML'
                  value='CCML'
                  name="CCML"
                  label="CCML"
                  className="checkboxColor"
                  checked={isCCMLChecked}
                  onChange={(e) => {
                    setIsCCMLChecked(e.target.checked);
                    updateIsCode(e.target.checked, 'CCML');
                  }}
                />
                <LdsCheckbox
                  id='THPC'
                  value='THPC'
                  name="THPC"
                  label="THPC"
                  className="checkboxColor"
                  checked={isTHPCChecked}
                  onChange={(e) => {
                    setIsTHPCChecked(e.target.checked);
                    updateIsCode(e.target.checked, 'THPC');
                  }}
                />
                <LdsCheckbox
                  id='DSST'
                  value='DSST'
                  name="DSST"
                  label="DSST"
                  className="checkboxColor"
                  checked={isDSSTChecked}
                  onChange={(e) => {
                    setIsDSSTChecked(e.target.checked);
                    updateIsCode(e.target.checked, 'DSST');
                  }}
                />
              </div> : ''}

              <LdsRadio
                id="com"
                label="Commercial"
                name="but"
                value="BRND"
                onChange={(e) => { setIsCode(e.target.value); setIsCodeType(e.target.value) }}
                error
              /></div>

          </div>
          <div className=' button-container'>
            {
              checkAddSubcategoryRole() &&
              <LdsButton className=" buttons" onClick={AddSubButton}>Request New Sub Category</LdsButton>
            }
            {
              checkAddMetaDataRole() &&
              <LdsButton className=" buttons" onClick={() => setIsAddNewCatModalOpen(true)}>Add New Category</LdsButton>
            }
            {
              checkApprovalRole() &&
              <LdsButton className=" buttons" onClick={RfaButton}>{sessionStorage.getItem('role') === 'omt_tacct_bl_metadata_requester' ? 'Check Requested Entry' : 'Review Approval Requests'}</LdsButton>
            }
            {
              checkEditCategoryRole() &&
              <Link to="/tacct/edit-category">
                <LdsButton className=" buttons">Edit Category</LdsButton>
              </Link>
            }
            {
              checkEditCategoryRole() &&
              <Link to="/tacct/edit-product">
                <LdsButton className=" buttons">Edit Product</LdsButton>
              </Link>
            }
            {
              checkEditCategoryRole() &&
                <LdsButton className="buttons" onClick={() => archiveIt()}>Archive</LdsButton>
            }

            <div className='second-row d-flex'>
              {!csvLoader ?
                <LdsButton className=" buttons" onClick={downloadExcel} label="download-csv" icon="DownloadSimple">Download as CSV</LdsButton> :
                <div class='loader-csv'>
                  <> <LdsLoadingSpinner size={30} animationSpeed={500} /> &nbsp;</>
                </div>


              }
              <LdsButton className="buttons" onClick={searchFld}>Search</LdsButton>
              <LdsButton className=" buttons" onClick={clearSearch}>Clear All</LdsButton>
              <LdsButton className=" buttons" onClick={goToArchive}>View Archive</LdsButton>
            </div>
          </div>

        </div>
      </LdsTile>


      <LdsTable className='table-bl'>
        <thead>
          <tr>
            <th className='ta-left'>
              SELECT
            </th>
            <th scope="col">
              <div className="ta-left attriDivCore pointer" onClick={() => setIsON(!statusSearchFld)}>
                STATUS
                <div class="iconStyle">
                  {statusSearchFld == false ?
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="CaretCircleDownFill"
                      class="icon-size icon-s "
                    /> : <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="CaretCircleUpFill"
                      class="icon-size icon-s"
                    />}
                </div>
              </div>
              {statusSearchFld &&
                <div className='checkField'>
                  <LdsCheckbox
                    id='approved'
                    value='APPROVED'
                    label='APPROVED'
                    name="appCheckbox"
                    onChange={(e) => checkStatus(e)}
                    checked={statusSearch.includes('APPROVED')}
                  />
                  <LdsCheckbox
                    id='REJECTED'
                    value='REJECTED'
                    label='REJECTED'
                    name="appCheckbox"
                    onChange={(e) => checkStatus(e)}
                    checked={statusSearch.includes('REJECTED')}
                  />
                  <LdsCheckbox
                    id='DRAFT'
                    value='DRAFT'
                    label='DRAFT'
                    name="appCheckbox"
                    onChange={(e) => checkStatus(e)}
                    checked={statusSearch.includes('DRAFT')}
                  />
                  <LdsButton className="ghost-outlined" onClick={() => { searchFld(); setIsON(false) }}>Apply</LdsButton>
                </div>
              }
            </th>
            <th scope="col">

              <div class="attriDivCore ta-left">
                Country
                <div class="iconStyle">
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    onClick={() => setIsCountry(!countrySearchFld)}
                  />
                </div>
              </div>
              {countrySearchFld &&
                <div className='textField'>
                  <LdsTextField
                    id='orgName'
                    value={countrySearch}
                    onChange={(e) => setcountrySearch(e.target.value)}
                    name='orgName' />
                </div>
              }
            </th>
            <th scope="col">
              <div class="attriDivCore ta-left">
                Product
                <div class="iconStyle">
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    onClick={() => setIsProduct(!productSearchFld)}
                  />
                </div>
              </div>
              {productSearchFld &&
                <div className='textField'>
                  <LdsTextField
                    id='orgName'
                    value={productSearch}
                    onChange={(e) => setproductSearch(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                    name='orgName' />
                </div>
              }
            </th>
            <th scope="col">
              <div class="attriDivCore ta-left">
                Category
                <div class="iconStyle">
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    onClick={() => setIsCategory(!categorySearchFld)}
                  />
                </div>
              </div>
              {categorySearchFld &&
                <div className='textField'>
                  <LdsTextField
                    id='orgName'
                    value={categorySearch}
                    onChange={(e) => setcategorySearch(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                    name='orgName' />
                </div>
              }
            </th>
            <th scope="col">
              <div class="attriDivCore ta-left">
                Sub-Category
                <div class="iconStyle">
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    onClick={() => setIsSubCategory(!subCategorySearchFld)}
                  />
                </div>
              </div>
              {subCategorySearchFld &&
                <div className='textField'>
                  <LdsTextField
                    id='orgName'
                    value={subCatSearch}
                    onChange={(e) => setsubCatSearch(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                    name='orgName' />
                </div>
              }
            </th>

            <th scope="col">
              <div class="attriDivCore ta-left">
                Category Operational Definition
                <div class="iconStyle">
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    onClick={() => setIsCatOpDef(!categoryOpDefinitionFld)}
                  />
                </div>
              </div>
              {categoryOpDefinitionFld &&
                <div className='textFieldCat'>
                  <LdsTextField
                    id='orgName'
                    value={catOpSearch}
                    onChange={(e) => setcatOpSearch(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                    name='orgName' />
                </div>
              }
            </th>
            <th scope="col">
              <div class="attriDivCore ta-left">
                Sub-Category Operational Definition
                <div class="iconStyle">
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    onClick={() => setIsSubOpDef(!subCategoryOpDefinitionSearchFld)}
                  />
                </div>
              </div>
              {subCategoryOpDefinitionSearchFld &&
                <div className='textField'>
                  <LdsTextField
                    id='orgName'
                    value={subCatOpSearch}
                    onChange={(e) => setsubCatOpSearch(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                    name='orgName' />
                </div>
              }
            </th>
            <th></th>
            <th className='ta-left' scope="col">VVPM</th>
            <th className='ta-left' scope="col">VVMC</th>
            <th className='ta-left' scope="col">AEM</th>
            <th></th>
          </tr>
        </thead>
        {!loading ? (data && data.length > 0 ?
          <tbody>
            {records.map((entry, rowIndex) =>
              <tr role="row" key={rowIndex} className={`table-ends ${entry.status === 'REJECTED' ? 'rejected' : ''}`} >
                <td>
                  <LdsCheckbox
                    checked={selectedIds.includes(entry.blue_list_subcategory_detail_id)} // Replace with your actual API response key
                    onChange={(event) => handleCheckboxChange(event, entry.blue_list_subcategory_detail_id)} // Replace with your actual API response key
                  />
                </td>
                <td className='ta-left' >
                  {entry.status === 'REJECTED' ? (
                    <div>
                      <LdsTooltip hideIcon>
                        <LdsTooltip.Text>{entry.status}</LdsTooltip.Text>
                        {entry.reject_note ? (
                          <LdsTooltip.Description>{entry.reject_note}</LdsTooltip.Description>
                        ) : (
                          <LdsTooltip.Description>No remarks found!</LdsTooltip.Description>
                        )}
                      </LdsTooltip>
                    </div>
                  ) : (
                    <div>{entry.status}</div>
                  )}
                </td>
                <td className='ta-left'>{entry.country_names.split(',').length > 3 ? "All" : entry.country_names}</td>
                <td className='ta-left'>{entry.product_name}</td>
                <td className='ta-left'>{entry.category}</td>
                <td className='ta-left'>{entry.subcategory}</td>
                <td className='ta-left'>{entry.category_definition}</td>
                <td className='ta-left'>{entry.subcategory_definition}</td>
                <td className='ta-left'>
                  <div className="row-style icon-class">
                    <LdsIcon className="table-icons"
                      description="Show record history"
                      label="History"
                      name="InfoFill"
                      onClick={() => { setAuditIsModalOpen(true); setTacticId(entry.blue_list_subcategory_detail_id) }}
                    />
                  </div>
                </td>
                <td className='ta-left'>{entry.vvpm_flag === true ? 'Y' : 'N'}</td>
                <td className='ta-left'>{entry.vvmc_flag === true ? 'Y' : 'N'}</td>
                <td className='ta-left'>{entry.aem_flag === true ? 'Y' : 'N'}</td>
                {checkEditCategoryRole() && <td className='ta-left'>
                  <div className="icon-col" onClick={() => editExistingData(entry.blue_list_subcategory_detail_id)}>
                    <LdsIcon className="table-icons" label='Edit Subcategory' name='PencilSimpleLineFill' />
                  </div>
                </td>}
              </tr>)}
          </tbody> : (<tbody>
            <div>
              <span className='no-data'>No Data Available</span>
            </div>
          </tbody>
          )
        ) : <LdsLoadingSpinner class='loader' size={50} animationSpeed={500} />}
      </LdsTable>

      {!loading &&
        <LdsPagination
          onLinkClick={clickLink}
          onNextClick={clickNext}
          onPrevClick={clickPrev}
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          pageRangeVisible={pageRangeVisible}
        />
      }
      <AddNewCatModal
        modalOpen={isAddNewCatModalOpen}
        setIsAddNewCatModalOpen={setIsAddNewCatModalOpen}
      />
      <HistoryModal
        modalOpen={isAuditModalOpen}
        setAuditIsModalOpen={setAuditIsModalOpen}
        tacticId={tacticId}
        sysName="tacct"
      />
    </>
  )
}