import React, { useState } from 'react';
import '../assets/pages/Chatbot.scss'; // Import the CSS file for the chatbot
import options from'../data/bot.json';
import { LdsAccordion, LdsButton, LdsIcon, LdsVideoPlayer } from '@elilillyco/ux-lds-react'
import Accordion from '../components/Accordion';

function Chatbot() {
  const [openAccordionId, setOpenAccordionId] = useState(null);

  const handleMainAccordionClick = (id) => {
    setOpenAccordionId(id === openAccordionId ? null : id);
  };

  const [video,setVideo] = useState(false)
  const [data,setData] = useState(options)
  console.log(data, 'data')
  const [isChatbotOpen, setChatbotOpen] = useState(false);
  const [messages, setMessages] = useState([
    { text: "Hello! How can I assist you today?", isBot: true }
  ]);

  const toggleChatbot = () => {
    setChatbotOpen(!isChatbotOpen);
  };

  const handleButtonClick = (response) => {
    setMessages([
      ...messages,
      { text: response, isBot: false },
      { text: data[response].description, isBot: true, link:data[response].link }
    ]);
    if(response==='Home'){
      setData(options)
    }
    else if(response='Bluelist'|| response==='Tonality'|| response==='Business Unit'){
      setData(options[response].features)
    }
  };

  const playVideo = (link) =>{
    window.open(link)
    setVideo(true)
  }

  const viewDoc = (link) =>{
    window.open(link)
    setVideo(true)
  }

  return (
    <>
    {video && <div className='player'>
      </div>}
      {/* Chatbot Initiate Button */}
      {!isChatbotOpen ? (
        <div className="chatbot-init-button">
          <div className="chatbot-init-open" onClick={toggleChatbot}>
          <LdsIcon name="ChatsFill" inline />
          </div>
        </div>
      ):(
       <div className='chatbot-init-button'>
        <div className='chatbot-init-close' onClick={toggleChatbot}>
        <LdsIcon name="X" inline />
        </div>
       </div>
      )}

      {/* Chatbot Layout */}
      {isChatbotOpen && (
        <div className="chatbot-layout">
          <div className="chatbot-header">
          <img src="/home.png" className="logo-image" />

          </div>
          <div className="chatbot-messages">
          {Object.keys(data).map((key) => {
          let subOptions = data[key]?.features || {};
          return (
            <Accordion
              key={key}
              id={key}
              title={key}
              isOpen={key === openAccordionId}
              onToggle={() => handleMainAccordionClick(key)}
            >
              <div>
                {data[key]?.description}
                {<LdsButton onClick={()=>viewDoc(data[key]?.doc)} className='document' name='files' icon='Files' classes='outlined' iconPosition='before' label="Download Feature Document">Download {key} Documentation</LdsButton>}
                {Object.keys(subOptions).map((subKey) => (
                  <Accordion
                    key={subKey}
                    id={subKey}
                    title={subKey}
                    isOpen={subOptions[subKey]?.isOpen || false}
                    onToggle={() => {
                      // Toggle the subaccordion's open state
                      subOptions[subKey].isOpen = !subOptions[subKey]?.isOpen;
                      setData({ ...data, [key]: { ...data[key], features: { ...subOptions } } });
                    }}
                  >
                    {subOptions[subKey]?.description}
                    {subOptions[subKey]?.link && <LdsButton className='video' onClick={()=>playVideo(subOptions[subKey].link)}>Play Demo</LdsButton>}
                    
                  </Accordion>
                ))}
              </div>
            </Accordion>
            
          );
        })}
          </div>
          <div className="chatbot-buttons">
          <LdsButton>Service Now Incident</LdsButton>
          </div>
         
        </div>
      )}
    </>
  );
}

export default Chatbot;
