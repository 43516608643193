import React, { useEffect, useState } from 'react'
import HeaderNav from '../../HeaderNav'
import { LdsButton, LdsTile, LdsLoadingSpinner, useToastContext } from '@elilillyco/ux-lds-react'
import ReusableTable from '../../shared/Table'
import { fetchTnData } from '../../../store/Components/TACCT/TN/tnoverview'
import { useDispatch } from 'react-redux'
import "../../../assets/components/TACCT/loader.scss"
import ExportDataCSV from '../../../data/utils/ExportDataCSV'


const TonalityHome = () => {
  const [design, setDesign] = useState()
  const [loader, setLoader] = useState()
  const dispatch = useDispatch();

  const infoToastConfig = {
    toastMessage: "Table has no data in it.",
    actionText: "",
    // actionCallback: () => console.log("View clicked"),
    variant: "informative",
    position: "top",
    align: "center",
    dismissible: true,
    light: false,
    timeout: 5000,
    inline: false,
    autoDismiss: true,
  };
  const { addToast } = useToastContext();

  useEffect(() => {
    dispatch(fetchTnData('overview'))
      .then((data) => (setLoader(true),  data?.payload?.data[0] !== undefined ?  setDesign(<><ReusableTable column={Object?.keys(data?.payload?.data[0]).slice(0,3)} tableData={data?.payload?.data} editData={editData}  /></>):addToast(infoToastConfig)))
  }, [])

  function editData(data) {
    window.location.href = `/tacct/tonality-ops/tonality-entry?id=${data}`
  }

  function checkNewRequestTNRole() {
    const role = sessionStorage.getItem('role');
    if (role === 'omt_tacct_bl_metadata_requester' || role === 'omt_tacct_admin' || role === 'omt_admin') {
      return true;
    }
    return false;
  }

  function checkApprovalRole() {
    const role = sessionStorage.getItem('role');
    if (role === 'omt_tacct_bl_approver_taxccb' || role === 'omt_tacct_admin' || role === 'omt_tacct_bl_approver_omni' || role === 'omt_admin') {
      return true;
    }
    return false;
  }

  function AddGrammeticalForm(){
    window.location.href = '/tacct/tonality-ops/tonality-entry'
  }

  function reqButton(){
    window.location.href='/tacct/tonality-ops/approval'
  }

  const downloadExcel =async() => {
    await dispatch(fetchTnData('overview')).then((data) => {
      ExportDataCSV(data?.payload.data, "TACCT_tonality")
      })
  }

  return (
    <>
      <HeaderNav msg={"Tonality"} />
      <LdsTile className='hero container' >
        <div className="overview-title">
          <span className='overview-text p-3'>Overview</span>
        </div>
        <div className='button-container'>
          {
            checkNewRequestTNRole() &&
            <LdsButton className="col buttons" onClick={AddGrammeticalForm} >Request New Tonality</LdsButton>
          }
          {
            checkApprovalRole() &&
            <LdsButton className="col buttons" onClick={reqButton} >Review Approval Requests</LdsButton>
          }
          <LdsButton className="col buttons" onClick={downloadExcel} label="download-csv" icon="DownloadSimple">Download As CSV</LdsButton>
        </div>
      </LdsTile>
      <div className='container-md'>
        {loader ? design : <div className='spinner container loaderAlignFix'>
          <LdsLoadingSpinner size={90} />
        </div>}
      </div>
    </>
  )
}

export default TonalityHome