import React, { useEffect, useState } from 'react';
import HeaderNav from '../HeaderNav';
import { LdsButton, LdsIcon, LdsLink, LdsLoadingSpinner, LdsPagination, LdsTable, LdsTextField, LdsTile, LdsModal, useLdsModal } from '@elilillyco/ux-lds-react';
import { useDispatch } from 'react-redux';
import { fetchCapData } from '../../store/Components/OMMS/capDataDetails';

const CapOverview = () => {
    const dispatch = useDispatch();
    const [capData, setCapData] = useState([]);
    const [openFields, setOpenFields] = useState([]);
    const [inputValues, setInputValues] = useState({});
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const { isModalOpen, setIsModalOpen } = useLdsModal();
    const [dataForfModal, setDataForModal] = useState([]);
    const [apiPayload, setApiPayload] = useState({
        delivery_source: "",
        department: "",
        disease_state_indication: "",
        page: currentPage,
        platform: "",
        product: "",
        tactic_end_date: "",
        tactic_start_date: "",
        verso_campaign_id: "",
        verso_tactic_id: "",
    });
    useEffect(() => {
        setApiPayload({
            delivery_source: inputValues['Delivery Source'] || "",
            page: currentPage,
            platform: inputValues['Platform'] || "",
            tactic_start_date: inputValues['Tactic Start Date'] || "",
            verso_campaign_id: inputValues['Verso Campaign Id'] || "",
            verso_tactic_id: inputValues['Verso Tactic Id'] || "",
        })
    }, [currentPage])
    useEffect(() => {
        dispatch(fetchCapData(apiPayload)).then((d) => {
            const data = d?.payload?.data || [];
            setCapData(data);
            setFilteredData(data);
        });
    }, [apiPayload, currentPage]);

    const handleHeaderClick = (index) => {
        setOpenFields(prev => {
            if (prev.includes(index)) {
                return prev.filter(i => i !== index);
            } else {
                return [...prev, index];
            }
        });
    };

    const handleInputChange = (e, key) => {
        const newValue = e.target.value;
        setInputValues({ ...inputValues, [key]: newValue });
    };

    const handleSearch = () => {
        setApiPayload({
            "verso_tactic_id": inputValues['Verso Tactic Id'] || "",
            "verso_campaign_id": inputValues['Verso Campaign Id'] || "",
            "delivery_source": inputValues['Delivery Source'] || "",
            "platform": inputValues['Platform'] || "",
            "tactic_start_date": inputValues['Tactic Start Date'] || "",
            "tactic_end_date": "",
            "product": "",
            "disease_state_indication": "",
            "department": "",
            "page": currentPage,
        })

    };

    const handleClear = () => {
        setOpenFields([]);
        setInputValues({});
        setApiPayload({
            delivery_source: "",
            department: "",
            disease_state_indication: "",
            page: currentPage,
            platform: "",
            product: "",
            tactic_end_date: "",
            tactic_start_date: "",
            verso_campaign_id: "",
            verso_tactic_id: "",
        })
        setFilteredData(capData);
    };

    const handleClick = (item) => {
        setDataForModal(item)
        setIsModalOpen(true)
    }

    return (
        <>
            <HeaderNav msg={"CAP DATA DETAILS"} />
            <div className="ms-3 mt-2 p-2">
                <LdsLink href="/omms">
                    <LdsIcon description="CaretLeft" label="search" name="CaretLeft" inline />
                    Back to Overview
                </LdsLink>
            </div>
            <span className="overview-text container ">Overview</span>
            <div className='container'>
                <LdsButton onClick={handleSearch}>
                    Search
                </LdsButton>
                &nbsp;
                <LdsButton onClick={handleClear}>
                    Clear
                </LdsButton>
            </div>
            <LdsTile>
                <div>
                    <LdsTable>
                        <thead>
                            <tr>
                                {['Verso Tactic Id', 'Verso Campaign Id', 'Delivery Source', 'Platform', 'Tactic Start Date'].map((header, index) => (
                                    <th key={index} className='ta-center' style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleHeaderClick(index)}>
                                        {header}
                                        {openFields.includes(index) && (
                                            <LdsTextField
                                                id={header.replace(/\s+/g, '').toLowerCase()}
                                                value={inputValues[header] || ''}
                                                onChange={(e) => handleInputChange(e, header)}
                                                onClick={(e) => e.stopPropagation()}
                                            />
                                        )}
                                    </th>
                                ))}
                                <th className='ta-center' style={{ fontWeight: 'bold' }}>Tactic End Date</th>
                                <th className='ta-center' style={{ fontWeight: 'bold' }}>Product</th>
                                <th className='ta-start' style={{ fontWeight: 'bold' }}>Disease State Indication</th>
                                <th className='ta-start' style={{ fontWeight: 'bold' }}>Department</th>
                                <th style={{ fontWeight: 'bold' }}>Audit Logs</th>
                            </tr>
                        </thead>
                        {filteredData?.length === 0 ? <LdsLoadingSpinner
                            animationSpeed={1000}
                            ariaLabel="loading"
                            id="demo-loading-spinner"
                            size={40}
                            svgTitle="loading"
                            className=' p-3 '
                        /> : <tbody>
                            {filteredData.map((item, index) => (
                                <tr key={index} role="row">
                                    <td className='ta-center'>{item.verso_tactic_id}</td>
                                    <td className='ta-center'>{item.verso_campaign_id}</td>
                                    <td className='ta-center'>{item.delivery_source}</td>
                                    <td className='ta-center'>{item.platform}</td>
                                    <td className='ta-center'>{item.tactic_start_date}</td>
                                    <td className='ta-center'>{item.tactic_end_date}</td>
                                    <td className='ta-center'>{item.product}</td>
                                    <td className='ta-start'>{item.disease_state_indication}</td>
                                    <td className='ta-start'>{item.department}</td>
                                    <td>
                                        <LdsIcon onClick={() => (handleClick(item))} name="InfoFill" style={{ maxWidth: '30px', maxHeight: '30px', float: "right" }} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>}
                    </LdsTable>
                    {console.log("cp", currentPage)}
                    <LdsPagination
                        currentPage={currentPage}
                        onNextClick={() => setApiPayload({ page: currentPage + 1 })}
                        onPrevClick={() => setApiPayload({ page: currentPage - 1 })}
                        setCurrentPage={setCurrentPage}
                        useButtons={true}
                    />
                </div>
            </LdsTile>
            <LdsModal
                modalId="testModal2"
                open={isModalOpen}
                setModalOpen={setIsModalOpen}
                persistent
            >
                <h3 className='container overview-text'>Audit Table</h3>
                <LdsTable>
                    <thead>
                        <tr>
                            {['Verso Tactic Id', 'Verso Campaign Id', 'Delivery Source', 'Platform', 'Tactic Start Date', 'Tactic End Date', 'Product', 'Disease State Indication', 'Department'].map((key, index) => (
                                <th className='ta-center' style={{ fontWeight: 'bold' }} key={index}>{key}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {dataForfModal ? (
                            <tr role="row">
                                <td className='ta-center'>{dataForfModal.verso_tactic_id}</td>
                                <td className='ta-center'>{dataForfModal.verso_campaign_id}</td>
                                <td className='ta-center'>{dataForfModal.delivery_source}</td>
                                <td className='ta-center'>{dataForfModal.platform}</td>
                                <td className='ta-center'>{dataForfModal.tactic_start_date}</td>
                                <td className='ta-center'>{dataForfModal.tactic_end_date}</td>
                                <td className='ta-center'>{dataForfModal.product}</td>
                                <td className='ta-start'>{dataForfModal.disease_state_indication}</td>
                                <td className='ta-start'>{dataForfModal.department}</td>
                            </tr>
                        ) : (
                            <tr>
                                <td colSpan="9" className="ta-center">No data available</td>
                            </tr>
                        )}
                    </tbody>
                </LdsTable>
                <LdsButton className="lds-button" onClick={() => setIsModalOpen(false)}>Okay</LdsButton>
            </LdsModal>


        </>
    );
};

export default React.memo(CapOverview);
